<template>
    <router-view></router-view>
</template>

<style>
    html, body {
        width: 100vw;
        height: 100vh;
        min-width: 1425px;
        overflow-y: hidden;
    }
    #nprogress .bar {
        /*自定义进度条颜色*/
        background: #2186c0 !important;
    }
</style>